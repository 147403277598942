// Name:            Iconnav
// Description:     Component to create icon navigations
//
// Component:       `uk-iconnav`
//
// Modifier:        `uk-iconnav-vertical`
//
// States:          `uk-active`
//
// ========================================================================


// Variables
// ========================================================================

$iconnav-margin-horizontal:                       $global-small-margin !default;
$iconnav-margin-vertical:                         $iconnav-margin-horizontal !default;

$iconnav-item-color:                              $global-muted-color !default;

$iconnav-item-hover-color:                        $global-color !default;

$iconnav-item-active-color:                       $global-color !default;


/* ========================================================================
   Component: Iconnav
 ========================================================================== */

/*
 * 1. Allow items to wrap into the next line
 * 2. Reset list
 * 3. Gutter
 */

.uk-iconnav {
    display: flex;
    /* 1 */
    flex-wrap: wrap;
    /* 2 */
    margin: 0;
    padding: 0;
    list-style: none;
    /* 3 */
    margin-left: (-$iconnav-margin-horizontal);
    @if(mixin-exists(hook-iconnav)) {@include hook-iconnav();}
}

/*
 * Space is allocated based on content dimensions, but shrinks: 0 1 auto
 * 1. Gutter
 */

.uk-iconnav > * {
    /* 1 */
    padding-left: $iconnav-margin-horizontal;
}


/* Items
 ========================================================================== */

/*
 * Items must target `a` elements to exclude other elements (e.g. dropdowns)
 * 1. Center content vertically if there is still some text
 * 2. Imitate white space gap when using flexbox
 * 3. Force text not to affect item height
 * 4. Style
 * 5. Required for `a` if there is still some text
 */

.uk-iconnav > * > a {
    /* 1 */
    display: flex;
    align-items: center;
    /* 2 */
    column-gap: 0.25em;
    /* 3 */
    line-height: 0;
    /* 4 */
    color: $iconnav-item-color;
    /* 5 */
    text-decoration: none;
    @if(mixin-exists(hook-iconnav-item)) {@include hook-iconnav-item();}
}

/* Hover */
.uk-iconnav > * > a:hover {
    color: $iconnav-item-hover-color;
    @if(mixin-exists(hook-iconnav-item-hover)) {@include hook-iconnav-item-hover();}
}

/* Active */
.uk-iconnav > .uk-active > a {
    color: $iconnav-item-active-color;
    @if(mixin-exists(hook-iconnav-item-active)) {@include hook-iconnav-item-active();}
}


/* Modifier: 'uk-iconnav-vertical'
 ========================================================================== */

/*
 * 1. Change direction
 * 2. Gutter
 */

.uk-iconnav-vertical {
    /* 1 */
    flex-direction: column;
    /* 2 */
    margin-left: 0;
    margin-top: (-$iconnav-margin-vertical);
}

/* 2 */
.uk-iconnav-vertical > * {
    padding-left: 0;
    padding-top: $iconnav-margin-vertical;
}


// Hooks
// ========================================================================

@if(mixin-exists(hook-iconnav-misc)) {@include hook-iconnav-misc();}

// @mixin hook-iconnav(){}
// @mixin hook-iconnav-item(){}
// @mixin hook-iconnav-item-hover(){}
// @mixin hook-iconnav-item-active(){}
// @mixin hook-iconnav-misc(){}


// Inverse
// ========================================================================

$inverse-iconnav-item-color:                $inverse-global-muted-color !default;
$inverse-iconnav-item-hover-color:          $inverse-global-color !default;
$inverse-iconnav-item-active-color:         $inverse-global-color !default;



// @mixin hook-inverse-iconnav-item(){}
// @mixin hook-inverse-iconnav-item-hover(){}
// @mixin hook-inverse-iconnav-item-active(){}

// Name:            Switcher
// Description:     Component to navigate through different content panes
//
// Component:       `uk-switcher`
//
// States:          `uk-active`
//
// ========================================================================


/* ========================================================================
   Component: Switcher
 ========================================================================== */

/*
 * Reset list
 */

.uk-switcher {
    margin: 0;
    padding: 0;
    list-style: none;
}


/* Items
 ========================================================================== */

/*
 * Hide not active items
 */

.uk-switcher > :not(.uk-active) { display: none; }

/*
 * Remove margin from the last-child
 */

.uk-switcher > * > :last-child { margin-bottom: 0; }


// Hooks
// ========================================================================

@if(mixin-exists(hook-switcher-misc)) {@include hook-switcher-misc();}

// @mixin hook-switcher-misc(){}
